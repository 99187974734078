<template>
  <div class="article-items">
    <el-table border :data="items" style="width: 100%">
      <el-table-column prop="" width="40">
        <template slot-scope="scope">
          <div class="flex justify-between">
            <i
              @click="handleDelete(scope.$index)"
              class="el-icon-circle-close text-gray-500 text-2xl cursor-pointer"
            ></i>
          </div>
        </template>
      </el-table-column>

      <el-table-column label="N° ARTICLE" prop="SKU" width="140">
        <template slot-scope="{ row }">
          <span>{{ row.article_sku }}</span>
        </template>
      </el-table-column>
      <el-table-column
        prop="article_name"
        label="DÉSIGNATION DE L'ARTICLE"
        min-width="200"
      >
        <template slot-scope="scope">
          <div class="article_name">
            <el-select
              size="medium"
              style="width: 100%"
              filterable
              v-model="scope.row.article_id"
              remote
              :remote-method="searchArticle"
              :loading="loadingArticle"
              placeholder="Recherche un article"
              clearable
              @change="
                selectArticle(scope.row.article_id, scope.row, scope.$index)
              "
            >
              <el-option
                v-for="item in articles"
                :key="item.article_id"
                :label="` ${item.article_name}`"
                :value="item.article_id"
              >
              </el-option>
            </el-select>
          </div>
        </template>
      </el-table-column>
      <el-table-column
        prop="price"
        align="right"
        label="QTÉ DISP. SOURCE"
        width="150"
      >
        <template slot-scope="{ row }">
          <div class="input-transparent2">
            <el-input-number
              size="medium"
              :controls="false"
              v-model="row.quantity_from"
              :disabled="true"
            />
          </div>
        </template>
      </el-table-column>

      <el-table-column
        prop="quantity"
        align="right"
        label="QTÉ A TRANSFEREE"
        width="200"
      >
        <template slot-scope="{ row }">
          <div class="input-transparent2">
            <el-input-number
              size="medium"
              controls-position="right"
              v-model="row.quantity_transfer"
              :min="0"
            />
          </div>
        </template>
      </el-table-column>
    </el-table>
    <div class="mt-2">
      <el-button icon="el-icon-plus" type="text" @click="addArticle"
        >Ajouter une ligne</el-button
      >
    </div>
  </div>
</template>

<script>
import { searchTransferStockArticle } from "@/api/article";
import { parseTime } from "@/utils";
import { toThousandFilter } from "@/Filters";

export default {
  name: "Add-Article",
  filters: {
    filterTime: parseTime,
    moneyFilter: toThousandFilter,
  },
  props: {
    items: {
      type: Array,
      required: true,
    },
    warehouseFrom: {
      type: String,
      required: true,
    },
    warehouseTo: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      dialogArticle: false,
      dialogWarehouse: false,
      articles: [],
      listArticle: [],
      loadingArticle: false,

      listQuery: {
        total: 0,
        page: 1,
        size: 20,
        sort: "desc",
        sortDir: "createdAt",
        name: undefined,
        warehouse_code: undefined,
      },
    };
  },
  mounted() {
    //this.fetchWarehouse();
  },
  methods: {
    async searchArticle(query) {
      // console.log(query);
      if (this.warehouseFrom === "" || this.warehouseFrom === "") {
        this.$message({
          message: "Merci de selectionner l'entrepôt source et destination",
          type: "warning",
        });
      } else {
        if (query.length !== "") {
          this.loadingArticle = true;
          await searchTransferStockArticle({
            name: query,
            warehouseFrom: this.warehouseFrom,
          })
            .then((res) => {
              this.articles = res.data;
              //this.items = res.data.items;
              this.loadingArticle = false;
            })
            .catch((err) => {
              if (err.data.status === 400) {
                this.$message({
                  message:
                    "Veuillez renseigner les informations obligatoire et les articles",
                  type: "warning",
                });
              } else if (err.data.status === 409 || err.data.status === 404) {
                this.$message({
                  message: err.data.message,
                  type: "warning",
                });
              } else {
                this.$message({
                  message: "Erreur serveur",
                  type: "danger",
                });
              }
            });
        }
      }
    },
    selectArticle(articleId, row) {
      const checkDuplicat = this.items.filter(
        (el) => el.article_id === articleId
      ).length;
      if (checkDuplicat > 1) {
        console.log("dupplicate", row);
        row.article_id = "";

        this.$message({
          message:
            "L'article a été dejà ajouter, veuillez  modifier la quantité",
          type: "warning",
        });
      } else {
        const item = this.articles.find((el) => el.article_id === articleId);

        row.article_id = item.article_id;
        row.article_name = item.article_name;
        row.article_sku = item.article_sku;
        row.quantity_from = item.quantity_from;
        row.quantity_to = item.quantity_to;
        row.quantity_transfer = 1;
      }
    },

    addArticle() {
      this.items.push({
        article_id: "",
        article_name: "",
        description: "",
        quantity_transfer: 0,
        quantity_from: 0,
        quantity_to: 0,
      });
    },

    handleDelete(index) {
      // const index = this.quote.items.findIndex((el) => el.articleId === id);
      this.items.splice(index, 1);
    },
  },
};
</script>

<style lang="scss" scoped>
.el-dialog-div {
  height: 70vh;
  overflow: hidden;
}
.list-article {
  height: 70vh;
  overflow-y: auto;
  .item {
    padding: 5px 0px;
    cursor: pointer;
    font-weight: 500;
  }
}
.article_name {
  font-weight: 500;
  font-size: 1rem;
  display: flex;
  flex-direction: column;
}
.article-desc {
  display: flex;
  padding-top: 5px;
  align-items: center;
  font-weight: 400;
  font-size: 14px;
}
</style>
