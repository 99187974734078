<template>
  <div class="flex flex-col font-Inter px-10">
    <div class="lg:flex lg:items-center lg:justify-between mt-4 mb-3 py-2">
      <div class="mt-1 flex flex-col justify-center">
        <h2
          class="
            text-xl
            font-medium
            leading-7
            text-gray-900
            sm:text-3xl sm:truncate
          "
        >
          Créer un transfert de stock
        </h2>
        <!-- <p class="text-gray-400 text-xs mt-2 w-1/2 text-justify">
            Pour enregistrer un devis le numero est générer par le système et
            veuillez renseigner les informations obligatoire (
            <strong class="text-red-500">*</strong>) et vous pouvez a tout
            moment convertir le devis en facture ou bon de commande
          </p> -->
      </div>
      <div class="flex justify-end">
        <el-button icon="el-icon-save" class="mr-2">Annuler</el-button>
        <el-button
          type="primary"
          icon="el-icon-save"
          :loading="loading"
          @click="createData('transferStockForm')"
          >Enregistrer</el-button
        >
      </div>
    </div>

    <div class="border bg-white p-6">
      <div class="flex flex-col mx-auto">
        <el-form
          :model="transferStock"
          :rules="rules"
          ref="transferStockForm"
          :label-position="labelPosition"
          class="mb-6"
        >
          <div class="flex justify-start">
            <el-form-item prop="warehouse_from" class="ml-2">
              <span slot="label" class="mr-7">Entrepôt source</span>
              <el-select
                v-model="transferStock.warehouse_from"
                placeholder="Veuillez selectionner"
                @change="handleSelectWarehouseFrom"
              >
                <el-option
                  v-for="(item, index) in warehouses"
                  :key="index"
                  :value="item.code"
                  :label="item.name"
                >
                </el-option>
              </el-select>
            </el-form-item>

            <el-form-item prop="warehouse_to" class="ml-2">
              <span slot="label" class="mr-7">Entrepôt de destination</span>
              <el-select
                v-model="transferStock.warehouse_to"
                @change="handleSelectWarehouseTo"
                placeholder="Veuillez selectionner"
              >
                <el-option
                  v-for="(item, index) in warehouses"
                  :key="index"
                  :value="item.code"
                  :label="item.name"
                >
                </el-option>
              </el-select>
            </el-form-item>

            <el-form-item class="ml-2">
              <span slot="label" class="mr-7">Réference</span>
              <el-input v-model="transferStock.reference"></el-input>
            </el-form-item>
          </div>
        </el-form>

        <add-article-transfer
          :items="items"
          :warehouseFrom="transferStock.warehouse_from"
          :warehouseTo="transferStock.warehouse_to"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { parseTime } from "@/utils";
import { toThousandFilter } from "@/Filters";

import { createTransferStock, fetchWarehouse } from "@/api/stock";
import AddArticleTransfer from "@/components/AddArticleTransfer";
export default {
  name: "CreateTransferStock",
  filters: {
    filterTime: parseTime,
    moneyFilter: toThousandFilter,
  },
  components: { AddArticleTransfer },
  data() {
    return {
      labelPosition: "top",
      componentLoanding: false,
      articles: [],
      warehouses: [],
      loading: false,
      articleName: "",
      loadingArticle: false,
      items: [
        {
          article_id: "",
          article_name: "",
          description: "",
          quantity_transfer: 0,
          quantity_from: 0,
          quantity_to: 0,
        },

        {
          article_id: "",
          article_name: "",
          description: "",
          quantity_transfer: 0,
          quantity_from: 0,
          quantity_to: 0,
        },
      ],
      transferStock: {
        warehouse_from: "",
        warehouse_to: "",
        reference: "",
        note: "",
        line_items: [],
      },

      rules: {
        warehouse_from: [
          {
            required: true,
            message: "L'entrepôt source est obligatoire",
            trigger: "change",
          },
        ],

        warehouse_to: [
          {
            required: true,
            message: "L'entrepôt destination est obligatoire",
            trigger: "change",
          },
        ],
      },
    };
  },
  computed: {},
  mounted() {
    this.fetchWarehouse();
  },
  methods: {
    async fetchWarehouse() {
      await fetchWarehouse()
        .then((res) => {
          this.warehouses = res.data;
        })
        .catch((error) => {
          this.$message.error(error.data.message);
        });
    },

    createData(transferStockForm) {
      this.$refs[transferStockForm].validate((valid) => {
        if (valid) {
          this.loading = true;
          console.log(this.items);
          this.transferStock.line_items = this.items.filter(
            (el) => el.article_id !== "" && el.article_id !== undefined
          );

          createTransferStock(this.transferStock)
            .then(() => {
              setTimeout(() => {
                this.loading = false;
                this.$router.push(`/stock/index?tab=TRANSFER-STOCK`);
              }, 1.5 * 1000);
            })
            .catch((err) => {
              this.loading = false;
              if (err.data.status === 400) {
                this.$message({
                  message:
                    "Veuillez renseigner les informations obligatoire et les articles",
                  type: "warning",
                });
              } else if (err.data.status === 409 || err.data.status === 404) {
                this.$message({
                  message: err.data.message,
                  type: "warning",
                });
              } else {
                this.$message({
                  message: "Erreur serveur",
                  type: "danger",
                });
              }
            });
        }
      });
    },
    handleSelectWarehouseFrom() {
      if (
        this.transferStock.warehouse_from === this.transferStock.warehouse_to
      ) {
        this.$message({
          message: "Veuillez selectionner  un entrepôt different",
          type: "warning",
        });
        this.transferStock.warehouse_from = "";
      }
    },

    handleSelectWarehouseTo() {
      if (
        this.transferStock.warehouse_from === this.transferStock.warehouse_to
      ) {
        this.$message({
          message: "Veuillez selectionner  un entrepôt different",
          type: "warning",
        });
        this.transferStock.warehouse_to = "";
      }
    },
  },
};
</script>

<style></style>
